import React from "react";
import "slick-carousel/slick/slick.css";
import "../scss/style.scss";
import dynamic from 'next/dynamic';
import type {AppProps} from 'next/app'
import ErrorBoundary from "../ErrorBoundary";
import {IProps} from "../types/homePageTypes";
import {PropsProvider} from "../context";
import Head from 'next/head';

const AppWrapper = dynamic(() => import('../AppWrapper'))
const CustomWrapper = dynamic(() => import('../CustomWrapper'))


export default function App({Component, pageProps}: AppProps) {
    return (
        <ErrorBoundary>
            <AppWrapper>
                <PropsProvider value={pageProps as unknown as IProps}>
                    <CustomWrapper Component={Component}/>
                </PropsProvider>
            </AppWrapper>
        </ErrorBoundary>
    );
}
